import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./Index.css";
import "../src/Style/index.css";
import "../src/Style/Responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import "react-alice-carousel/lib/alice-carousel.css";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
