import React from "react";
import Nav from "react-bootstrap/Nav";
import Line from "../assets/Images/Line.png";

function FooterComponent({
  Lien,
  Lien1,
  Lien2,
  Lien3,
  LienW,
  NameLien,
  NameLien1,
  NameLien2,
  NameLien3,
  NameTitre,
  NameLienW,
  Target,
  Target1,
  Target2,
  Target3,
  TargetW,
  Top,
}) {
  return (
    <div className="col-md-6 col-lg-4 col-xl-4 ">
      <div className="contIcon">
        <div className="pt-3 position-relative containSuivez">
          <div className="position-absolute " style={{ top: `${Top}` }}>
            <img src={Line} />
          </div>
          <p className="text-white fw-bold fs-6 mb-4 titreContact">
            {" "}
            {NameTitre}
          </p>

          <div className="listes">
            <Nav>
              <ul className="text-white contain-liste">
                <li className="">
                  <Nav.Link href={Lien} target={Target} className="text-white">
                    {NameLien}
                  </Nav.Link>
                </li>
                <li className="">
                  <Nav.Link
                    href={Lien1}
                    target={Target1}
                    className="text-white"
                  >
                    {NameLien1}
                  </Nav.Link>
                </li>
                <li className="">
                  <Nav.Link
                    href={Lien2}
                    target={Target2}
                    className="text-white"
                  >
                    {NameLien2}
                  </Nav.Link>
                </li>
                <li className="">
                  <Nav.Link
                    href={Lien3}
                    target={Target3}
                    className="text-white"
                  >
                    {NameLien3}
                  </Nav.Link>
                </li>
                <li className="">
                  <Nav.Link
                    href={LienW}
                    target={TargetW}
                    className="text-white"
                  >
                    {NameLienW}
                  </Nav.Link>
                </li>
              </ul>
            </Nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterComponent;
