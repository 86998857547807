import React from 'react'
import RectangleMarron from '../assets/Images/RectangleMarron.png'

function CardSolutionComponent({
    Image,
    Text
}) {
  return (
    <div className="col-md-6 col-lg-4 position-relative pb-3">
        <div className="position-absolute positionRectangle">
            <img src={RectangleMarron} alt="" />
        </div>
        <div className='card shadow rounded-5 border-white bg-white h-75 m-5'>
            <div className="d-flex flex-column justify-content-between">
                <div className="card-header border-0">
                    <div className="text-center">
                        <img src={Image} alt="Une image" style={{width: '30%', height: '30%'}} />
                    </div>
                </div>
                <div className="card-body">
                    <div className="text-center">
                        <p className="paragrapheSolution" style={{fontSize: '20px'}}>
                            {Text}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CardSolutionComponent