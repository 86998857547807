import React, { useState } from "react";
import MarginTop from "./MarginTop";
import CardTarifComponent from "./CardTarifComponent";
import { NavLink } from "react-router-dom";
import Check from "../assets/icons/check.png";
import Close from "../assets/icons/close.png";
import Deco from "../assets/Images/deco.png";

function TarifComponent() {
  const [showTab1, setShowTab1] = useState(true);

  return (
    <section id="nosTarifs">
      <MarginTop />
      <div className="container-fluid">
        <div className="contain-solutions">
          <div className="contain-solution">
            <h1 className="text-center titre-section">Nos tarifs</h1>
            <p className="text-center text-paragraphe">
              Choisissez la formule qui vous convient !
            </p>
            <div className="tarif-container-section position-relative">
              <div id="tabs" className="container-tabs-tarif">
                <div className="container-text-label-swith-tab">
                  <p className="text-label-swith-tab mb-0">Par Mois</p>
                </div>
                <label for="home-ch" id="home-lb" className="switch">
                  <input
                    type="checkbox"
                    name="tabs"
                    id="home-ch"
                    onClick={() => setShowTab1(!showTab1)}
                  />
                  <div></div>
                </label>
                <div className="container-text-label-swith-tab">
                  <p className="text-label-swith-tab mb-0">Par An</p>
                </div>
              </div>
              <div className="content-img-deco-tarif-section">
                <img src={Deco} alt="Deco" className="img-deco-tarif-section" />
              </div>
              <div id="text">
                {showTab1 ? (
                  <div className="row mt-4 mb-0 m-2">
                    <CardTarifComponent
                      TitreHeader="Formule Boutique"
                      Argent="25000"
                      Text1="Module de gestion de commande"
                      Text2="Module de gestion de stocks"
                      Text3="Module de gestion de livraisons"
                      Text4="Nombre de produits illimité"
                      Text5={
                        <span className="text-fonction-not-available">
                          Site ecommerce
                        </span>
                      }
                      Text6={
                        <span className="text-fonction-not-available">
                          Application mobile ecommerce
                        </span>
                      }
                      Text7={
                        <span className="text-fonction-not-available">
                          Nombre de produits limité à 10
                        </span>
                      }
                      Text8={
                        <span className="text-fonction-not-available">
                          Hébergement inclus
                        </span>
                      }
                      Text9={
                        <span className="text-fonction-not-available">
                          Maintenance inclus
                        </span>
                      }
                      Text10={
                        <span className="text-fonction-not-available">
                          Nom de domaine boutique.easymarket.sn offert
                        </span>
                      }
                      Icon1={Check}
                      Icon2={Check}
                      Icon3={Check}
                      Icon4={Check}
                      Icon5={Close}
                      Icon6={Close}
                      Icon7={Close}
                      Icon8={Close}
                      Icon9={Close}
                      Icon10={Close}
                      periode="Mois"
                      Button={
                        <NavLink
                          to="https://tally.so/r/w72JoR"
                          className="btn btn-theme-easy-market"
                        >
                          JE DÉMARRE MON MOIS D’ESSAI
                        </NavLink>
                      }
                    />
                    <CardTarifComponent
                      TitreHeader="Formule Boutique +"
                      Argent="25000"
                      Text1="Module de gestion de commande"
                      Text2="Module de gestion de stocks"
                      Text3="Module de gestion de livraisons"
                      Text4={
                        <span className="text-fonction-not-available">
                          Nombre de produits illimité
                        </span>
                      }
                      Text5="Site ecommerce"
                      Text6={
                        <span className="text-fonction-not-available">
                          Application mobile ecommerce
                        </span>
                      }
                      Text7="Nombre de produits limité à 10 "
                      Text8={
                        <span className="text-fonction-not-available">
                          Hébergement inclus
                        </span>
                      }
                      Text9={
                        <span className="text-fonction-not-available">
                          Maintenance inclus
                        </span>
                      }
                      Text10={
                        <span className="text-fonction-not-available">
                          Nom de domaine boutique.easymarket.sn offert
                        </span>
                      }
                      Icon1={Check}
                      Icon2={Check}
                      Icon3={Check}
                      Icon4={Close}
                      Icon5={Check}
                      Icon6={Close}
                      Icon7={Check}
                      Icon8={Close}
                      Icon9={Close}
                      Icon10={Close}
                      periode="Mois"
                      Button={
                        <NavLink
                          to="https://tally.so/r/w72JoR"
                          className="btn btn-theme-easy-market"
                        >
                          JE DÉMARRE MON MOIS D’ESSAI
                        </NavLink>
                      }
                    />
                    <CardTarifComponent
                      TitreHeader="Formule Marque"
                      Argent="50000"
                      Text1="Module de gestion de commande"
                      Text2="Module de gestion de stocks"
                      Text3="Module de gestion de livraisons"
                      Text4="Nombre de produits illimité"
                      Text5="Site ecommerce"
                      Text6={
                        <span className="text-fonction-not-available">
                          Application mobile ecommerce
                        </span>
                      }
                      Text7={
                        <span className="text-fonction-not-available">
                          Nombre de produits limité à 10
                        </span>
                      }
                      Text8="Hébergement inclus"
                      Text9="Maintenance inclus"
                      Text10="Nom de domaine boutique.easymarket.sn offert"
                      Icon1={Check}
                      Icon2={Check}
                      Icon3={Check}
                      Icon4={Check}
                      Icon5={Check}
                      Icon6={Close}
                      Icon7={Close}
                      Icon8={Check}
                      Icon9={Check}
                      Icon10={Check}
                      periode="Mois"
                      Button={
                        <NavLink
                          to="https://tally.so/r/w72JoR"
                          className="btn btn-theme-easy-market"
                        >
                          JE DÉMARRE MON MOIS D’ESSAI
                        </NavLink>
                      }
                    />
                    <CardTarifComponent
                      TitreHeader="Formule Marque +"
                      Argent="75000"
                      Text1="Module de gestion de commande"
                      Text2="Module de gestion de stocks"
                      Text3="Module de gestion de livraisons"
                      Text4="Nombre de produits illimité"
                      Text5="Site ecommerce"
                      Text6="Application mobile ecommerce"
                      Text7={
                        <span className="text-fonction-not-available">
                          Nombre de produits limité à 10
                        </span>
                      }
                      Text8="Hébergement inclus"
                      Text9="Maintenance inclus"
                      Text10="Nom de domaine boutique.easymarket.sn offert"
                      Icon1={Check}
                      Icon2={Check}
                      Icon3={Check}
                      Icon4={Check}
                      Icon5={Check}
                      Icon6={Check}
                      Icon7={Close}
                      Icon8={Check}
                      Icon9={Check}
                      Icon10={Check}
                      periode="Mois"
                      Button={
                        <NavLink
                          to="https://tally.so/r/w72JoR"
                          className="btn btn-theme-easy-market"
                        >
                          JE DÉMARRE MON MOIS D’ESSAI
                        </NavLink>
                      }
                    />
                  </div>
                ) : (
                  <div className="row mt-4 mb-0 m-2">
                    <CardTarifComponent
                      TitreHeader="Formule Boutique"
                      Argent="270000"
                      Text1="Module de gestion de commande"
                      Text2="Module de gestion de stocks"
                      Text3="Module de gestion de livraisons"
                      Text4="Nombre de produits illimité"
                      Text5={
                        <span className="text-fonction-not-available">
                          Site ecommerce
                        </span>
                      }
                      Text6={
                        <span className="text-fonction-not-available">
                          Application mobile ecommerce
                        </span>
                      }
                      Text7={
                        <span className="text-fonction-not-available">
                          Nombre de produits limité à 10
                        </span>
                      }
                      Text8={
                        <span className="text-fonction-not-available">
                          Hébergement inclus
                        </span>
                      }
                      Text9={
                        <span className="text-fonction-not-available">
                          Maintenance inclus
                        </span>
                      }
                      Text10={
                        <span className="text-fonction-not-available">
                          Nom de domaine boutique.easymarket.sn offert
                        </span>
                      }
                      Icon1={Check}
                      Icon2={Check}
                      Icon3={Check}
                      Icon4={Check}
                      Icon5={Close}
                      Icon6={Close}
                      Icon7={Close}
                      Icon8={Close}
                      Icon9={Close}
                      Icon10={Close}
                      periode="An"
                      Button={
                        <NavLink
                          to="https://tally.so/r/w72JoR"
                          className="btn btn-theme-easy-market"
                        >
                          JE DÉMARRE MON MOIS D’ESSAI
                        </NavLink>
                      }
                    />
                    <CardTarifComponent
                      TitreHeader="Formule Boutique +"
                      Argent="270000"
                      Text1="Module de gestion de commande"
                      Text2="Module de gestion de stocks"
                      Text3="Module de gestion de livraisons"
                      Text4={
                        <span className="text-fonction-not-available">
                          Nombre de produits illimité
                        </span>
                      }
                      Text5="Site ecommerce"
                      Text6={
                        <span className="text-fonction-not-available">
                          Application mobile ecommerce
                        </span>
                      }
                      Text7="Nombre de produits limité à 10 "
                      Text8={
                        <span className="text-fonction-not-available">
                          Hébergement inclus
                        </span>
                      }
                      Text9={
                        <span className="text-fonction-not-available">
                          Maintenance inclus
                        </span>
                      }
                      Text10={
                        <span className="text-fonction-not-available">
                          Nom de domaine boutique.easymarket.sn offert
                        </span>
                      }
                      Icon1={Check}
                      Icon2={Check}
                      Icon3={Check}
                      Icon4={Close}
                      Icon5={Check}
                      Icon6={Close}
                      Icon7={Check}
                      Icon8={Close}
                      Icon9={Close}
                      Icon10={Close}
                      periode="An"
                      Button={
                        <NavLink
                          to="https://tally.so/r/w72JoR"
                          className="btn btn-theme-easy-market"
                        >
                          JE DÉMARRE MON MOIS D’ESSAI
                        </NavLink>
                      }
                    />
                    <CardTarifComponent
                      TitreHeader="Formule Marque"
                      Argent="540000"
                      Text1="Module de gestion de commande"
                      Text2="Module de gestion de stocks"
                      Text3="Module de gestion de livraisons"
                      Text4="Nombre de produits illimité"
                      Text5="Site ecommerce"
                      Text6={
                        <span className="text-fonction-not-available">
                          Application mobile ecommerce
                        </span>
                      }
                      Text7={
                        <span className="text-fonction-not-available">
                          Nombre de produits limité à 10
                        </span>
                      }
                      Text8="Hébergement inclus"
                      Text9="Maintenance inclus"
                      Text10="Nom de domaine boutique.easymarket.sn offert"
                      Icon1={Check}
                      Icon2={Check}
                      Icon3={Check}
                      Icon4={Check}
                      Icon5={Check}
                      Icon6={Close}
                      Icon7={Close}
                      Icon8={Check}
                      Icon9={Check}
                      Icon10={Check}
                      periode="An"
                      Button={
                        <NavLink
                          to="https://tally.so/r/w72JoR"
                          className="btn btn-theme-easy-market"
                        >
                          JE DÉMARRE MON MOIS D’ESSAI
                        </NavLink>
                      }
                    />
                    <CardTarifComponent
                      TitreHeader="Formule Marque +"
                      Argent="810000"
                      Text1="Module de gestion de commande"
                      Text2="Module de gestion de stocks"
                      Text3="Module de gestion de livraisons"
                      Text4="Nombre de produits illimité"
                      Text5="Site ecommerce"
                      Text6="Application mobile ecommerce"
                      Text7={
                        <span className="text-fonction-not-available">
                          Nombre de produits limité à 10
                        </span>
                      }
                      Text8="Hébergement inclus"
                      Text9="Maintenance inclus"
                      Text10="Nom de domaine boutique.easymarket.sn offert"
                      Icon1={Check}
                      Icon2={Check}
                      Icon3={Check}
                      Icon4={Check}
                      Icon5={Check}
                      Icon6={Check}
                      Icon7={Close}
                      Icon8={Check}
                      Icon9={Check}
                      Icon10={Check}
                      periode="An"
                      Button={
                        <NavLink
                          to="https://tally.so/r/w72JoR"
                          className="btn btn-theme-easy-market"
                        >
                          JE DÉMARRE MON MOIS D’ESSAI
                        </NavLink>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TarifComponent;
