import React from "react";
import MarginTop from "./MarginTop";
import CardSolutionComponent from "./CardSolutionComponent";
import Home from "../assets/Images/home.png";
import Outil1 from "../assets/Images/outil1.png";
import OutilStock from "../assets/Images/outilStock.png";
import "animate.css";

function SolutionComponent() {
  return (
    <section id="solution">
      <MarginTop />
      <div className="">
        <div className="container">
          <div className="contain-solutions">
            <div className="contain-solution">
              <h1 className="text-center titre-section">La Solution</h1>
              <div className="d-flex justify-content-center container-text">
                <p className="text-center text-paragraphe">
                  Easymarket, la{" "}
                  <span className="fw-bold">boite à outils </span>
                  qui vous permet d’obtenir une{" "}
                  <span className="fw-bold">
                    {" "}
                    gestion de stocks automatisée{" "}
                  </span>{" "}
                  et une
                  <span className="fw-bold">
                    {" "}
                    plateforme ecommerce personnalisée
                  </span>{" "}
                  à partir de{" "}
                  <span className="fw-bold">25 000 FCFA / Mois </span> en{" "}
                  <span className="fw-bold">moins de 2 semaines. </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <CardSolutionComponent
              Image={Home}
              Text="Site e-commerce Personnalisé"
            />
            <CardSolutionComponent
              Image={Outil1}
              Text="Outil de gestion 
                des commandes 
                et de livraisons"
            />
            <CardSolutionComponent
              Image={OutilStock}
              Text="Outil de gestion de stocks"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SolutionComponent;
