import React from "react";
import HeaderCompnent from "../Components/HeaderCompnent";
import SolutionComponent from "../Components/SolutionComponent";
import FonctionnaliteComponent from "../Components/FonctionnaliteComponent";
import TarifComponent from "../Components/TarifComponent";
import PresentationComponent from "../Components/PresentationComponent";
import SectionConfiance from "../Components/SectionConfiance";
import "./Accueil.css";

function Accueil() {
  return (
    <div className="animate__animated  animate__fadeIn">
      <HeaderCompnent />
      <SolutionComponent />
      <FonctionnaliteComponent />
      <TarifComponent />
      <PresentationComponent />
      {/* <ContactComponent/> */}
      <SectionConfiance />
    </div>
  );
}

export default Accueil;
