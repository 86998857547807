import React from 'react'
import MarginTop from './MarginTop'
// import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'
import Presentation from '../assets/Video/video.mp4'
import ImageVideo from '../assets/Images/imageVideo.png'

function PresentationComponent() {
  return (
    <section id='presentation'>
        <MarginTop/>
        <div className="container">
            <div className="contain-solution">
                <h1 className="text-center titre-section">Présentation</h1>
            </div>
        </div>
        <div className="mt-5 contain-video">
            <video src={Presentation} poster={ImageVideo} type="video/ogg"
                controls className='w-100 videos'
            />
        </div>
    </section>
  )
}

export default PresentationComponent