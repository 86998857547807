import React from 'react'


function CheckIconComponent({
    Text,
    Icon
}) {
  return (
    <>
        <div className="d-flex paragraphe">
            <p>{Icon}</p> &ensp; <p>{Text}</p>
        </div>
        
    </>
  )
}

export default CheckIconComponent