import React from "react"
import Router from "./Components/Router"

function App() {


  return (
    <>
      <Router/>
    </>
  )
}

export default App
