import React from "react";
import Logo2 from "../assets/Images/logoEMfooter.png";
import { BiLogoWhatsapp, BiPhoneCall } from "react-icons/bi";
import FooterComponent from "../Components/FooterComponent";
import FooterIconNumComponent from "../Components/FooterIconNumComponent";
import Line from "../assets/Images/Line.png";
import Nav from "react-bootstrap/Nav";

function Footer() {
  return (
    <footer className="bg-footer">
      <div className="container pt-5">
        <div className="row pb-5 flex-footer">
          <div className="col-md-6 col-lg-4 col-xl-4 part1">
            <div className="containerPart1">
              <div className="text-justify mb-4">
                <img src={Logo2} alt="" className="logofooter" />
              </div>
              <div className="">
                <p className="text-white fw-bold fs-5 titreContact">
                  Contactez-nous
                </p>
                <FooterIconNumComponent
                  Nom="WhatsApp"
                  LienWhatsapp={"https://wa.me/message/CAQO4LDBV2JCI1"}
                  Num="+221 78 826 77 00"
                  Icon={<BiLogoWhatsapp />}
                />
                <FooterIconNumComponent
                  Nom="Téléphone"
                  LienWhatsapp={"tel:+221788267700"}
                  Num="+221 78 826 77 00"
                  Icon={<BiPhoneCall />}
                />
              </div>
            </div>
          </div>
          <FooterComponent
            NameTitre="Suivez-nous"
            Lien={"https://www.linkedin.com/company/easymarket-sénégal/"}
            NameLien="LinkedIn"
            Target="_target"
            Lien1={
              "https://www.instagram.com/easymarket_sn?igsh=MWN0eHhlMzZtcWM2YQ%3D%3D&utm_source=qr"
            }
            NameLien1="Instagram"
            Target1="_target"
            Lien2={
              "https://www.facebook.com/easymarket.senegal?mibextid=LQQJ4d"
            }
            NameLien2="Facebook"
            Target2="_target"
            Lien3={"https://www.tiktok.com/@_easy_market_?_t=8k4deUCf6aP&_r=1"}
            NameLien3="Tiktok"
            TargetW="_target"
            LienW={"https://chat.whatsapp.com/BcakJ8Bzsu0BRyk5Jj6Ooz"}
            NameLienW="Communauté Whatsapp"
            Target3="_target"
            DisplayListe="none"
            Top={"16%"}
          />
          <div className="col-md-6 col-lg-4 col-xl-4 ">
            <div className="contIcon">
              <div className="pt-3 position-relative containSuivez">
                <div className="position-absolute " style={{ top: "11%" }}>
                  <img src={Line} />
                </div>
                <p className="text-white fw-bold fs-6 mb-4 titreContact">
                  Liens utiles
                </p>

                <div className="listes">
                  <Nav>
                    <ul className="text-white contain-liste">
                      {LinkFooter.map((item) => (
                        <li className="" key={item.id}>
                          <Nav.Link
                            href={item.link}
                            target={item.target}
                            className="text-white"
                          >
                            {item.title}
                          </Nav.Link>
                        </li>
                      ))}
                    </ul>
                  </Nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="text-center">
          <p className="text-center text-white mb-0 copyRight">
            © 2023 All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

const LinkFooter = [
  {
    id: 1,
    title: "La solution",
    link: "#solution",
    target: "",
  },
  {
    id: 2,
    title: "Les fonctionnalités",
    link: "#fonctionnalite",
    target: "",
  },
  {
    id: 3,
    title: "Nos tarifs",
    link: "#nosTarifs",
    target: "",
  },
  {
    id: 4,
    title: "Présentation",
    link: "#presentation",
    target: "",
  },
  {
    id: 5,
    title: "Contactez-nous",
    link: "https://tally.so/r/w72JoR",
    target: "_blank",
  },
];
