import React from 'react'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom' 
import Menu from '../Layouts/Menu'
import Footer from '../Layouts/Footer'
import Accueil from '../Pages/Accueil'



function Router() {
    const Layout = () => {
        return(
            <>
                <Menu/>
                <Outlet/>
                <Footer/>
            </>
        )
    }

    const BrowserRoutes = createBrowserRouter([
        {
            path: "/",
            element: <Layout/>,
            children: [
                {
                    path: "/",
                    element: <Accueil/>,
                },
                {
                    // path: "/",
                    // element: <Layout/>,
                }
            ]

        }
    ])

  return (
    <RouterProvider router= {BrowserRoutes} />
  )
}

export default Router