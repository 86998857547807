import React from 'react'

function CardFonctionnaliteComponent({
    Titre,
    children
}) {
  return (
    <div className='col-md-6 pb-5'>
        <div className="card m-2 m-1 shadow rounded-5 h-100 bg-white border-white ">
            <div className="d-flex flex-column justify-content-between">
                <div className="card-header border-0">
                    <p className="fs-5 fw-bolder paragraphe text-center">
                       {Titre}
                    </p>
                </div>
                <div className="card-body paragraphe">
                    {children}
                </div>
            </div>
        </div>
    </div>
  )
}

export default CardFonctionnaliteComponent