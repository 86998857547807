import { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../assets/Images/logoEM.png";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import Button from "react-bootstrap/Button";

function Menu() {
  // const location = useLocation();

  const [expanded, setExpanded] = useState(false);

  const handleNavToggle = () => {
    setExpanded(!expanded);
  };

  const handleNavLinkedClick = () => {
    if (expanded) {
      setExpanded(false);
    }
  };

  return (
    <Navbar
      expand="lg"
      className="shadow position-fixed w-100 bg-white z-1"
      expanded={expanded}
      onToggle={handleNavToggle}
    >
      <Container className="justify-content-between">
        <Navbar.Brand href="/#accueil" className="navbar-brand color-logo">
          <img src={Logo} alt="Logo" className="w-100" />
        </Navbar.Brand>
        {/* Personnaliser le bouton burger */}
        <Button
          onClick={handleNavToggle}
          className="hamburger-icon bg-white border-0 text-dark d-lg-none"
        >
          <GiHamburgerMenu className="fs-1" />
        </Button>
        <Navbar.Collapse
          id="navbarSupportedContent"
          className="flex-grow-0 w-100 align-items-center"
        >
          <Nav className="ms-auto me-auto d-flex gap-4 align-items-center justify-content-between">
            <Nav.Link
              href="#solution"
              className="nav-link lh-lg navMenu"
              onClick={handleNavLinkedClick}
            >
              La solution
            </Nav.Link>
            <Nav.Link
              href="#fonctionnalite"
              className="nav-link navMenu"
              onClick={handleNavLinkedClick}
            >
              Les fonctionnalités
            </Nav.Link>
            <Nav.Link
              href="#nosTarifs"
              className="nav-link navMenu"
              onClick={handleNavLinkedClick}
            >
              Nos tarifs
            </Nav.Link>
            <Nav.Link
              href="#presentation"
              className="nav-link navMenu"
              onClick={handleNavLinkedClick}
            >
              Présentation
            </Nav.Link>
          </Nav>
          <div>
            <NavLink
              to="https://tally.so/r/w72JoR"
              target="_blank"
              className="btn btn-contact-us"
            >
              Contactez-nous
            </NavLink>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
// function NavLinked({ to, children }) {
//   const isActive = location.hash === to;

//   return (
//     <Nav.Link
//       as={Link}
//       to={to}
//       className={`nav-link navMenu ${isActive ? "active" : ""}`}
//     >
//       {children}
//     </Nav.Link>
//   );
// }

export default Menu;
