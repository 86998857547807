import React, { useState, useRef } from "react";
import MarginTop from "./MarginTop";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SendBtn from "../assets/Images/sendBtn.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "@formspree/react";
import { FaSpinner } from "react-icons/fa";
import Torobee from "../assets/Images/partner/torobee.png";
import ToutDedans from "../assets/Images/partner/tout-dedans.png";
import Volkeno from "../assets/Images/partner/volkeno.png";
import Spr from "../assets/Images/partner/spr.png";
import BalyStore from "../assets/Images/partner/baly-store.png";
import FloraFlow from "../assets/Images/partner/flora-flow.png";
import Bally from "../assets/Images/partner/bally.png";
import Tayeur from "../assets/Images/partner/tayeur.png";
import AliceCarousel from "react-alice-carousel";

export const responsive = {
  0: { items: 1 },
  768: { items: 2 },
  1024: { items: 6 },
};

function SectionConfiance() {
  const initialValues = { email: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formId, setFormId] = useState("FormNewsletter");
  const [state, handleSubmit] = useForm("mqkvdgqd", {}, `#${formId}`);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef();

  const resetForm = () => {
    setFormValues(initialValues);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const response = await handleSubmit(e);

    if (state.succeeded) {
      toast.success("Merci, nous vous informerons", {
        position: "bottom-left",
      });
      // Réinitialisez le champ d'email après l'envoi avec succès
      resetForm();

      formRef.current.reset();
    }
    setIsSubmitting(false);
  };

  const isEmailValid = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  return (
    <section>
      <MarginTop />
      <div className="container">
        <div className="contain-solution">
          <h1 className="text-center titre-section">
            Ils nous ont fait confiance
          </h1>
        </div>
      </div>
      <div className="mt-2 contain-confiance p-5">
        <div className="w-100">
          <div
            class="content-partner-list my-4"
            data-animated
            //  data-slide-count="7"
          >
            <div class="items">
              <AliceCarousel
                mouseTracking
                items={Data.map((item) => {
                  return (
                    <div class="partner-item" key={item?.id}>
                      <img
                        src={item?.img}
                        alt=""
                        className="img-partner-logo"
                      />
                    </div>
                  );
                })}
                responsive={responsive}
                autoPlayStrategy="default"
                controlsStrategy="alternate"
                autoPlay={true}
                infinite={true}
                disableButtonsControls={true}
                autoPlayInterval={2000}
                disableDotsControls={true}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="containConf">
        <div className="container p-4">
          <div className="row align-items-center">
            <div className="col-md-3 d-flex justify-content-center newsLetter">
              <div className="containConfiance">
                <p className="mb-0 pb-0 fw-bold fs-6 text-body-secondary">
                  NEWSLETTER
                </p>
                <p className="fw-semibold fs-6 text-body-secondary">
                  Restez informer
                </p>
              </div>
            </div>
            <div className="col-md-9 componentInputNewsletter">
              <Form
                className="d-flex align-items-baseline contain-form"
                onSubmit={(e) => handleFormSubmit(e, formId)}
                ref={formRef}
                id={formId}
              >
                <Form.Control
                  type="email"
                  id="email"
                  name="email"
                  value={formValues.email}
                  placeholder="Votre adresse e-mail..."
                  className={`text-secondary fw-lighter p-2 ps-4 formSearch `}
                  onChange={(e) => {
                    const email = e.target.value;
                    setFormValues({ ...formValues, email });
                  }}
                />
                <br />

                <Button
                  className={`btn-icone ${
                    isSubmitting ? "rotate-animation" : ""
                  }`}
                  type="submit"
                  disabled={!isEmailValid(formValues.email) || isSubmitting}
                >
                  {isSubmitting ? (
                    <FaSpinner className="fs-2 text-success fw-bold" />
                  ) : (
                    <img src={SendBtn} alt="" className="" />
                  )}
                </Button>
                <ToastContainer />
              </Form>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
}

export default SectionConfiance;

const Data = [
  {
    id: 1,
    img: Torobee,
  },
  {
    id: 2,
    img: ToutDedans,
  },
  {
    id: 3,
    img: Volkeno,
  },
  {
    id: 4,
    img: Spr,
  },
  {
    id: 5,
    img: BalyStore,
  },
  {
    id: 6,
    img: FloraFlow,
  },
  {
    id: 7,
    img: Bally,
  },
  {
    id: 8,
    img: Tayeur,
  },
  {
    id: 9,
    img: Torobee,
  },
  {
    id: 10,
    img: ToutDedans,
  },
  {
    id: 11,
    img: Volkeno,
  },
  {
    id: 12,
    img: Spr,
  },
  {
    id: 13,
    img: BalyStore,
  },
  {
    id: 14,
    img: FloraFlow,
  },
  {
    id: 15,
    img: Bally,
  },
  {
    id: 16,
    img: Tayeur,
  },
];
