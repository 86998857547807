import React from "react";
import MarginTop from "./MarginTop";
import IphoneXR from "../assets/Images/iPhoneXR.png";
import { NavLink } from "react-router-dom";

function HeaderCompnent() {
  return (
    <section id="accueil" className="container-header">
      <MarginTop />
      <div className="container">
        <div className="container-fils">
          <div className="row">
            <div className="col-12 col-md-7 col-lg-7 col-xs-12">
              <div className="text-light">
                <p className=" pb-3 lh-sm text-header1">
                  La Boite à Outils des Vendeurs !
                </p>
                <p className=" pb-3 text-header2">
                  Obtenez un site ecommerce à partir de 25 000 FCFA /mois et un
                  outil de gestion performant.
                </p>
                <NavLink
                  to="https://tally.so/r/w72JoR"
                  target="_blank"
                  className="btn btn-banner-home-page"
                >
                  démarreR 1 MOIS D’ESSAI GRATUIT
                </NavLink>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-5 col-xs-12 position-relative">
              <div className="position-absolute container-image-iphone">
                <img src={IphoneXR} alt="" className="w-75 image-iphone" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <MarginTop />
    </section>
  );
}

export default HeaderCompnent;
