import React from 'react'
import MarginTop from './MarginTop'
import CardFonctionnaliteComponent from './CardFonctionnaliteComponent'
import CheckIconComponent from './CheckIconComponent'
import {AiOutlineCheck} from 'react-icons/ai'

function FonctionnaliteComponent() {
  return (
    <section id='fonctionnalite'>
        <MarginTop/>
        <section  className='pt-5 pb-4 container-fontionnalite'>  
            <div className="container">
                <div className="contain-solutions">
                    <div className="contain-solution">
                        <h1 className="text-center titre-section">Les fonctionnalités</h1>
                        <div className="row mt-5 row-pad">
                            {/* Fonctionnalité côté acheteur */}
                            <CardFonctionnaliteComponent Titre="Côté acheteur">
                                <CheckIconComponent
                                    Icon={<AiOutlineCheck className='rounded-circle fs-4 p-1 d-flex justify-content center align-items-center check-icon' />}
                                    Text='Voir l’ensemble des produits disponible'
                                />
                                <CheckIconComponent
                                    Icon={<AiOutlineCheck className='rounded-circle fs-4 p-1 d-flex justify-content center align-items-center check-icon' />}
                                    Text='Filtrer les produits par catégorie'
                                />
                                <CheckIconComponent
                                    Icon={<AiOutlineCheck className='rounded-circle fs-4 p-1 d-flex justify-content center align-items-center check-icon' />}
                                    Text='Mettre dans son panier les produits'
                                />
                                <CheckIconComponent
                                    Icon={<AiOutlineCheck className='rounded-circle fs-4 p-1 d-flex justify-content center align-items-center check-icon' />}
                                    Text='Acheter en ligne ou payer à la livraison'
                                />
                            </CardFonctionnaliteComponent>
                            {/* Fonctionnalité côté vendeur */}
                            <CardFonctionnaliteComponent Titre="Côté vendeur">
                                <CheckIconComponent
                                    Icon={<AiOutlineCheck className='rounded-circle fs-4 p-1 d-flex justify-content center align-items-center check-icon' />}
                                    Text='Accéder à son tableau de bord avec différents indicateurs de performance'
                                />
                                <CheckIconComponent
                                    Icon={<AiOutlineCheck className='rounded-circle fs-4 p-1 d-flex justify-content center align-items-center check-icon' />}
                                    Text='Créer et mettre en ligne ses produits'
                                />
                                <CheckIconComponent
                                    Icon={<AiOutlineCheck className='rounded-circle fs-4 p-1 d-flex justify-content center align-items-center check-icon' />}
                                    Text='Renseigner le stock de chaque produit'
                                />
                                <CheckIconComponent
                                    Icon={<AiOutlineCheck className='rounded-circle fs-4 p-1 d-flex justify-content center align-items-center check-icon' />}
                                    Text='Traiter les commandes'
                                />
                                <CheckIconComponent
                                    Icon={<AiOutlineCheck className='rounded-circle fs-4 p-1 d-flex justify-content center align-items-center check-icon' />}
                                    Text='Ajouter des commandes manuellement'
                                />
                            </CardFonctionnaliteComponent>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    </section>
  )
}

export default FonctionnaliteComponent