import React from 'react'

function MarginTop({
    MarginTop
}) {
  return (
    <div>
        <div className="marginTop" style={{ paddingTop: '10%' }}>
            {MarginTop}
        </div>
    </div>
  )
}

export default MarginTop