import React from "react";

function CardTarifComponent({
  TitreHeader,
  Argent,
  Text1,
  Text2,
  Text3,
  Text4,
  Text5,
  Text6,
  Text7,
  Text8,
  Text9,
  Text10,
  Button,
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
  Icon10,
  periode,
}) {
  return (
    <div className="col-md-6 col-lg-3 col-sm-12 col-xs-12 container-headerTarif mb-3">
      <div
        className="h-100 card w-100 cardTarif shadow"
        style={{
          background: "white",
          borderRadius: "12px",
          padding: "20px",
          border: 0,
        }}
      >
        <div className="h-100 d-flex flex-column justify-content-between w-100">
          <div className="card-header p-0" style={{ borderBottom: "0" }}>
            <div className="container-head">
              <p className="titreTarif">{TitreHeader}</p>
              <div className="d-flex align-items-center gap-2 justify-content-center text-center w-100">
                <span className="containArgent">{Argent} FCFA HT </span>
                <span className="text-currency">/{periode}</span>
              </div>
            </div>
          </div>
          <div className="card-footer p-0 border-0 my-4">
            <div className="d-flex justify-content-center bouton">{Button}</div>
          </div>
          <div className="card-body border-0 d-flex justify-content-center p-0">
            <div
              className="pt-4 "
              style={{ fontSize: "16px", fontWeight: "400" }}
            >
              <div className="container-info-tarif-text pb-3">
                <div className="content-icon-statut-tari">
                  <img src={Icon1} alt="Tarif" className="icon-tarif-statut" />
                </div>
                <p className="textTarif">{Text1}</p>
              </div>
              <div className="container-info-tarif-text pb-3">
                <div className="content-icon-statut-tari">
                  <img src={Icon2} alt="Tarif" className="icon-tarif-statut" />
                </div>
                <p className="textTarif">{Text2}</p>
              </div>
              <div className="container-info-tarif-text pb-3">
                <div className="content-icon-statut-tari">
                  <img src={Icon3} alt="Tarif" className="icon-tarif-statut" />
                </div>
                <p className="textTarif">{Text3}</p>
              </div>
              <div className="container-info-tarif-text pb-3">
                <div className="content-icon-statut-tari">
                  <img src={Icon4} alt="Tarif" className="icon-tarif-statut" />
                </div>
                <p className="textTarif">{Text4}</p>
              </div>
              <div className="container-info-tarif-text pb-3">
                <div className="content-icon-statut-tari">
                  <img src={Icon5} alt="Tarif" className="icon-tarif-statut" />
                </div>
                <p className="textTarif">{Text5}</p>
              </div>
              <div className="container-info-tarif-text pb-3">
                <div className="content-icon-statut-tari">
                  <img src={Icon6} alt="Tarif" className="icon-tarif-statut" />
                </div>
                <p className="textTarif">{Text6}</p>
              </div>
              <div className="container-info-tarif-text pb-3">
                <div className="content-icon-statut-tari">
                  <img src={Icon7} alt="Tarif" className="icon-tarif-statut" />
                </div>
                <p className="textTarif">{Text7}</p>
              </div>
              <div className="container-info-tarif-text pb-3">
                <div className="content-icon-statut-tari">
                  <img src={Icon8} alt="Tarif" className="icon-tarif-statut" />
                </div>
                <p className="textTarif">{Text8}</p>
              </div>
              <div className="container-info-tarif-text pb-3">
                <div className="content-icon-statut-tari">
                  <img src={Icon9} alt="Tarif" className="icon-tarif-statut" />
                </div>
                <p className="textTarif">{Text9}</p>
              </div>
              <div className="container-info-tarif-text pb-3">
                <div className="content-icon-statut-tari">
                  <img src={Icon10} alt="Tarif" className="icon-tarif-statut" />
                </div>
                <p className="textTarif">{Text10}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardTarifComponent;
